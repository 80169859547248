import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@clerk+nextjs@4.27.6_next@14.1.0_react-dom@18.2.0_react@18.2.0/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@clerk+nextjs@4.27.6_next@14.1.0_react-dom@18.2.0_react@18.2.0/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@clerk+nextjs@4.27.6_next@14.1.0_react-dom@18.2.0_react@18.2.0/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@clerk+nextjs@4.27.6_next@14.1.0_react-dom@18.2.0_react@18.2.0/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_@babel+core@7.25.2_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/sonner@1.2.4_react-dom@18.2.0_react@18.2.0/node_modules/sonner/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/provider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/atoms/RandomPrompt.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/atoms/Socials.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/molecules/AIMessageBox.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/molecules/BGEffect.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/molecules/Footer.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/molecules/Modal.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/molecules/Nav.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/molecules/UserMessageBox.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/organisms/ChatInput.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/organisms/Message.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/templates/Chat.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/templates/ClientWrapper.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/utils/DatadogInit.tsx")