"use client";

import { useEffect, useState } from "react";

export default function Provider({ children }: { children: React.ReactNode }) {
  // Hydration error fixes
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);
  return <>{isClient && <>{children}</>}</>;
}
