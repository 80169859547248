// Necessary if using App Router to ensure this file runs on the client
"use client";

import { datadogRum } from "@datadog/browser-rum";

datadogRum.init({
  applicationId: "53410440-01fc-4c9a-8e27-183b3fe5127d",
  clientToken: "pub3a21868af709eaa222f3bb664786295c",
  site: "datadoghq.com",
  service: "web",
  env: process.env.NEXT_PUBLIC_NODE_ENV,
  // Specify a version number to identify the deployed version of your application in Datadog
  version: process.env.NEXT_PUBLIC_VERSION,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
  allowedTracingUrls: ["https://solan.ai", (url) => url.startsWith("https://lg.solan.ai")]
});

export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}